import React from 'react';
import styled from 'styled-components';
import { spaceDt, screenMin, screenMax, spaceMb } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container, PageHeader } from '@fragments';
import { VikingShip } from '@illustrations';
import dataCSL from '../CaseStudyList.data';

const HeaderContent = styled(PageHeader)`
  width: inherit;
  margin-bottom: 0;
  padding-right: 0;
  padding-left: 0;

  ${screenMax('lg')} {
    margin-top: 0;
  }
`;

const SectionHeaderWrapper = styled.div`
  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(30)};
  }
`;

const SectionHeaderBase = styled(Container)`
  flex-direction: column-reverse;

  ${screenMin('lg')} {
    flex-grow: 1;
    flex-direction: row-reverse;
    padding-right: ${spaceDt(3)};
  }
`;

const SectionHeaderColumn = styled.div`
  ${screenMin('lg')} {
    min-height: 100%;
    flex: 0 0 70%;
  }
`;

const SectionHeaderContent = styled(SectionHeaderColumn)`
  display: flex;
  flex-direction: column;

  ${screenMin('lg')} {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    flex: 0 0 30%;
  }
`;

const SectionHeaderPicture = styled(VikingShip)`
  max-width: 100%;
  width: ${toVW(376, 'mobile')};
  height: ${toVW(243, 'mobile')};
  margin-bottom: ${spaceMb(6)};

  ${screenMin('lg')} {
    margin-top: ${spaceDt(-7)};
    width: ${toVW(1054, 'desktop')};
    height: ${toVW(679, 'desktop')};
    margin-bottom: ${spaceDt(12.5)};
  }
`;

const {
  sectionHeader: { name, headline },
} = dataCSL;

const SectionHeader = () => (
  <SectionHeaderBase>
    <SectionHeaderColumn>
      <SectionHeaderPicture />
    </SectionHeaderColumn>

    <SectionHeaderContent>
      <SectionHeaderWrapper>
        <HeaderContent name={name} headline={headline} />
      </SectionHeaderWrapper>
    </SectionHeaderContent>
  </SectionHeaderBase>
);

export default SectionHeader;
