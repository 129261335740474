import React from 'react';
import PropTypes from 'prop-types';
import { Flags, SEO, TagGroup } from '@fragments';
import { getLogo } from '@helpers/methods';
import SectionHeader from './fragments/SectionHeader';
import { ContainerGrid, CardCaseGrid } from './CaseStudyList.styled';

const CaseStudyList = ({ pageContext: { posts } }) => (
  <>
    <SEO
      title="Case studies | Ragnarson"
      description="Startups we've worked with grown from an idea, only founders, no team through MVP, multiple rounds of financing, to become market leaders with 100+ people organizations"
    />

    <SectionHeader />

    <ContainerGrid>
      {posts.map(({ id, meta: { slug, thumbnail, countries, tags } }) => {
        const LogoComponent = getLogo(thumbnail?.svgId);

        return (
          <CardCaseGrid
            key={id}
            to={`/case-studies/${slug}`}
            logo={
              (
                <LogoComponent
                  config={{
                    widthMb: 200,
                    width: 250,
                  }}
                />
              ) ||
              thumbnail?.svgId ||
              'ruby'
            }
            title={thumbnail.title}
            body={thumbnail.body}
            slotFlags={<Flags flags={countries} />}
            slotTags={<TagGroup size="small" tags={tags.slice(0, 5)} />}
          />
        );
      })}
    </ContainerGrid>
  </>
);

export default CaseStudyList;

CaseStudyList.propTypes = {
  pageContext: PropTypes.shape({
    posts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        meta: PropTypes.shape({
          slug: PropTypes.string,
          thumbnail: PropTypes.shape({
            title: PropTypes.string,
            body: PropTypes.string,
            svgId: PropTypes.string,
          }),
          countries: PropTypes.arrayOf(PropTypes.string),
          tags: PropTypes.arrayOf(PropTypes.string),
        }),
      })
    ),
  }).isRequired,
};
