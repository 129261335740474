import styled from 'styled-components';
import { CardCase, Container } from '@fragments';
import { toVW } from '@helpers/methods';
import { screenMin, spaceMb, spaceDt } from '@helpers/styles';

const ContainerGrid = styled(Container)`
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: ${spaceMb(8)};
  align-items: flex-start;
`;

const CardCaseGrid = styled(CardCase)`
  margin-right: 0 !important;
  margin-bottom: ${spaceMb(6)};

  ${screenMin('lg')} {
    margin: 0 ${spaceDt(1)} ${spaceDt(8)} ${spaceDt(1)} !important;
    max-width: ${toVW(170, 'desktop')};
  }
`;

export { ContainerGrid, CardCaseGrid };
