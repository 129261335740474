import React from 'react';
import { TextEffect } from '@fragments';

const dataCSL = {
  sectionHeader: {
    name: 'Case studies',
    headline: <TextEffect effect="highlight">Our work</TextEffect>,
  },
};

export default dataCSL;
